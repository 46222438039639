import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface ITemplateConfig
{
    layout: {
        variant: string;                   // options: Dark, Light & Transparent
        menuPosition: string;              // options: Side, Top (Note: Use 'Side' for Vertical Menu & 'Top' for Horizontal Menu )
        customizer: {
            hidden: boolean;               // options: true, false
        };
        navbar: {
          type: string;                     // options: Static & Fixed
        }
        sidebar: { //Options for Vertical Side menu
            collapsed: boolean;             // options: true, false
            size: string;                   // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
            backgroundColor: string;        // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

            /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
              bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
            */
            backgroundImage: boolean;        // Options: true, false | Set true to show background image
            backgroundImageURL: string;
        }
    };
}


@Injectable({
  providedIn: "root"
})
export class ConfigService {
  public templateConf: ITemplateConfig = this.setConfigValue();
  templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();

  constructor() {
  }


  /**
   * Set the default configuration values for the template.
   * 
   * @returns {ITemplateConfig} The default configuration object.
   */
  setConfigValue(): ITemplateConfig {
    return this.templateConf = {
      // The layout configuration
      layout: {
        // The color variant of the layout
        variant: "Light",
        // The position of the menu
        menuPosition: "Top",
        // Configuration for the customizer
        customizer: {
          // Whether the customizer is hidden or not
          hidden: true
        },
        // Configuration for the navbar
        navbar: {
          // The type of the navbar
          type: 'Static'
        },
        // Configuration for the sidebar
        sidebar: {
          // Whether the sidebar is collapsed or not
          collapsed: false,
          // The size of the sidebar
          size: "sidebar-md",
          // The background color of the sidebar
          backgroundColor: "man-of-steel",
          // Whether to show a background image or not
          backgroundImage: true,
          // The URL of the background image
          backgroundImageURL: "assets/img/sidebar-bg/01.jpg"
        }
      }
    };
  }

  /**
   * Updates the template configuration with the provided changes.
   * If a property is not provided in the tempConfig object, it will not be modified.
   * 
   * @param {ITemplateConfig} tempConfig - Object containing the changes to be applied to the template configuration.
   */
  applyTemplateConfigChange(tempConfig: ITemplateConfig) {
    // Merge the provided changes with the current template configuration
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    // Notify all subscribers of the updated template configuration
    this.templateConfSubject.next(this.templateConf);
  }

}
