import { createReducer, on } from "@ngrx/store";
import { setCity } from "../actions/cityChange.actions";

export interface CityState {
  cityId: string | null;
}

export const initialState: CityState = {
  cityId: null,
};

const _cityReducer = createReducer(
  initialState,
  on(setCity, (state, { cityId }) => ({ ...state, cityId }))
);

export function cityReducer(state, action) {
  return _cityReducer(state, action);
}
