import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path:'',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m=>m.FullPagesModule)
  }

];
