import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  /**
   * Retrieves the item with the specified key from local storage.
   * 
   * @param {string} key - The key of the item to retrieve.
   * @return {any} The value of the item, or null if the item does not exist.
   */
  getItem(key: string): any {
      // Retrieve the item from local storage with the specified key.
      return localStorage.getItem(key);
  }

  /**
   * Sets the value of the item with the specified key in local storage.
   * 
   * @param {string} key - The key of the item to set.
   * @param {string} value - The value to set for the item.
   */
  setItem(key: string, value: string): void {
      // Set the item in local storage with the specified key and value.
      localStorage.setItem(key, value);
  }

  /**
   * Removes the item with the specified key from local storage.
   *
   * @param {string} key - The key of the item to remove.
   * @return {void} This function does not return anything.
   */
  removeItem(key: string): void {
      // Remove the item with the specified key from local storage.
      localStorage.removeItem(key);
  }

  /**
   * Clears all items from local storage.
   *
   * @return {void} This function does not return anything.
   */
  clear(): void {
      // Clear all items from local storage.
      localStorage.clear();
  }

  /**
   * Retrieves an object from local storage using the specified key.
   * If the item exists, it is parsed from JSON and returned. Otherwise, null is returned.
   *
   * @param {string} key - The key of the item to retrieve.
   * @return {any} The parsed object, or null if the item does not exist.
   */
  getObject(key: any): any {
      // Retrieve the item from local storage with the specified key.
      const item = localStorage.getItem(key);

      // If the item exists, parse it from JSON and return it. Otherwise, return null.
      return item ? JSON.parse(item) : null;
  }

  /**
   * Sets the value of the item with the specified key in local storage.
   * The value is first converted to a JSON string and then stored in local storage.
   *
   * @param {string} key - The key of the item to set.
   * @param {any} value - The value to set for the item.
   * @return {any} The value that was set in local storage.
   */
  setObject(key: string, value: any): any {
      // Convert the value to a JSON string and store it in local storage with the specified key.
      localStorage.setItem(key, JSON.stringify(value));

      // Return the value that was just set.
      return value;
  }

}
