import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private toggleSidebar = new Subject<boolean>(); // small screen
  private overlaySidebarToggle = new Subject<boolean>();
  private toggleNotiSidebar = new Subject<boolean>();


  // Observable
  toggleSidebar$ = this.toggleSidebar.asObservable();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();

  /**
   * Emit a boolean value to toggle the sidebar in small screens.
   *
   * @param {boolean} toggle - The value to toggle the sidebar.
   */
  toggleSidebarSmallScreen(toggle: boolean) {
    // Emit the toggle value to subscribers of `toggleSidebar$`.
    this.toggleSidebar.next(toggle);
  }

  /**
   * Toggle the overlay sidebar.
   *
   * @param {boolean} toggle - The value to toggle the overlay sidebar.
   */
  overlaySidebartoggle(toggle: boolean) {
    // Emit the toggle value to subscribers of `overlaySidebarToggle$`.
    // This will trigger subscribers to open or close the overlay sidebar.
    this.overlaySidebarToggle.next(toggle);
  }

  /**
   * Toggle the notification sidebar.
   *
   * @param {boolean} toggle - The value to toggle the notification sidebar.
   */
  toggleNotificationSidebar(toggle: boolean) {
    // Emit the toggle value to subscribers of `toggleNotiSidebar$`.
    // This will trigger subscribers to open or close the notification sidebar.
    this.toggleNotiSidebar.next(toggle);
  }

}
